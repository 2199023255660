import estilos from './TheMain.module.css'
import { Route,Routes,useLocation} from 'react-router-dom';

import Content from './Content'
import ContentDetail from './ContentDetail'
import UserLogin from '../../auth/LoginUser'
import {useState} from 'react'
import CursosVideos from '../../users/CursosVideos'
import Ruta from '../../users/Ruta'
import ProtectedRoute from '../../services/ProtectedRoute'

const TheMain = ()=> {
   
    const location = useLocation();
    const currentPath = location.pathname;
    const parts = currentPath.split('/'); 
    let menu = parts[1]; 
    const opcion = parts[2]; 

    if(menu=='')
    {
       menu = 'home'
    }
    
    const [componente,setComponente] = useState(null);
    
    const urls = {
        menu:menu,
        opcion:opcion
    }

    return (
	    <main className={estilos.main}>
                {
                    !opcion && menu  != 'login-user' && menu  != 'user-cursos'  && menu  != 'user-ruta'  ?  
                        <Routes>
                           <Route 
                              path={menu+'/:id?'}  
                              element={<Content  
                                          location={location} 
                                          urls={urls} 
                                       />} 
                           /> 
                            
                            <Route index element={<Content  
                                          location={location} 
                                          urls={urls} 
                                       />} />

                            

                        </Routes> 
                    : ( menu  != 'login-user' && menu  != 'user-cursos'  && menu  != 'user-ruta' ? 
                        <ContentDetail 
                            location={location} 
                            nomPost={opcion}
                        />  :<> 
                                <Routes>
                                    <Route path="login-user" element={<UserLogin/>} />
                                </Routes>
                                <ProtectedRoute> 
                                    <Routes> 
                                        <Route path="user-cursos" element={<CursosVideos/>} />
                                        <Route path="user-ruta/:id" element={<Ruta/>} />
                                    </Routes>
                                </ProtectedRoute>
                            </>
                    )
                } 
        </main> 
	) 
}

export default TheMain;