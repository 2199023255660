
import TheBody from './components/body/TheBody';
import TheHeader from './components/header/TheHeader';
import TheMain from './components/body/TheMain';
import TheFooter from './components/footer/TheFooter';
import RoutersAdmin from './admin/blog/Routers';
import { BrowserRouter as Router} from 'react-router-dom';

function App() {
  const currentPath = window.location.pathname;
  const parts = currentPath.split('/'); 
  const admin = parts[1]; 

  return (
    <Router>
      {   
       admin !='admin' && admin !='login'  ? 
        <TheBody>
          <TheHeader/>
          <TheMain/>
          <TheFooter/>
        </TheBody> : (
           admin =='admin' ? <RoutersAdmin /> : ''
        )
      }
   </Router> 
  );
}

export default App;
