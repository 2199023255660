import {Navigate} from 'react-router-dom'
import t from './TokenService'

const ProtectedRoute = ({children}) => {
	const fvUser = t.getToken()
	
	if (!fvUser) {
		return <Navigate to='/login-user' />;
	}
    
    return children
}

export default ProtectedRoute