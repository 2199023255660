import { useState, useEffect } from 'react'
import BlogService from '../services/BlogService'

function useOnClickShowModalTarea() {
    const [show, setShow] = useState(false);
    const [tareaContent, setTareaContent] = useState('');
    const [tareaName, setTareaName] = useState('');

    const handleOnClickShowModalTarea = (id) => {
        setShow(!show)

        const form = {
            id:id
        }

        if(!show){
            const data = BlogService.getData('blog/tarea/',id).then((data) => {
                setTareaContent(data.dtTarea.contenido)
                setTareaName(data.dtTarea.nombre)
            })
        }
      
    }
    return {show,handleOnClickShowModalTarea, tareaContent, tareaName};
}

export default useOnClickShowModalTarea;