import estilos from './Tooltip.module.css'

const Tooltip = (props) => {
    return(
        <div className={estilos.tooltip}>
            {props.message}
        </div>
    )
}

export default Tooltip;