import estilosForm from '../../components/UI/Form.module.css'
import Input from '../../components/UI/Input'
import Select from '../../components/UI/Select'
import Container from '../../components/body/Container'
import Form from '../../components/UI/Form'
import NavService from '../../services/NavService'
import Button from '../../components/UI/Button'
import ModalSmail from '../../components/UI/ModalSmall'
import TextEditor from '../../components/UI/TextEditor'
import TextTarea from '../../components/UI/TextTarea'

import { useState, useEffect } from 'react'


const Registro = () => {

    useEffect(() => {
        NavService.getBlog().then((data)=> {

            const newArray = [];
            for (const item of data.dtMenu) {
                newArray.push({'value':item.id,'text':item.nombre});
            }
            setDtMenuSelect(newArray)
        })

    },[])


    const [dtMenuSelect,setDtMenuSelect] = useState(null)
    
    const [showModalSamll,setShowModalSamll] = useState(null)

    const [dtTematica,setDtTematica] = useState([])
    const [dtSubTema,setDtSubTema] = useState([])
    const [dtTemaFinal,setDtTemaFinal] = useState([])

    const [subTemaText,setSubTemaText] = useState([])
    const [idTema,setIdTema] = useState(null)

    useEffect(() => {
        const arrayFusionado = [...dtTematica, ...dtSubTema];
        setDtTemaFinal(arrayFusionado);

        console.log(dtTemaFinal)
    
    },[dtSubTema,dtTematica])

    const [dtForm,setDtForm] = useState({
        temaPost:'',
        url:'',
        tematica:''
    })

    const dtTipoDePost = [
        {'value':'','text':'Seleccione...'},
        {'value':0,'text':'Post'},
        {'value':1,'text':'Curso'},
        {'value':2,'text':'Video'},
        {'value':3,'text':'Proyecto'},
    ]

    const handleOnchange = (e) => {
    
        if(e.target.name=='tematica'){
            setDtForm({
                ...dtForm,
                tematica:e.target.value
            });
        }
  
        if(e.target.name=='subTema'){
            setSubTemaText( e.target.value);
        }
        
    }

    const handleAddTema = (e) => {
        const obNew = {id:dtTematica.length, nombre:dtForm.tematica,nuevo:1}
        setDtTematica([...dtTematica,obNew])
    }
    const handleAddSubTema = () => {
        const obNew = {id:dtSubTema.length, nombre:subTemaText,nuevo:1,idTema:idTema}
        setDtSubTema([...dtSubTema,obNew])
    }

    const openModalSamal = (id) => {
        setShowModalSamll(true)
        setIdTema(id)

    }
    const closeModalSamal = () => {
        setShowModalSamll(false)
    }

    const handleDeleteTema = (id) => {
        let nuevoArray = dtTematica.filter((objeto) => objeto.id !== id);
        setDtSubTema(nuevoArray);
        nuevoArray = dtSubTema.filter((objeto) => objeto.idTema !== id);
        setDtTematica(nuevoArray);
    }

    const handleDeleteSubTema = (id) => {
        
        const nuevoArray = dtSubTema.filter((objeto) => objeto.id !== id);
        setDtSubTema(nuevoArray);
    }
 
    let idCab = 0.4;

    return(<Container>
        {showModalSamll && <ModalSmail onClick={closeModalSamal}>
            <div className='row'>
                <div className="col col-11">
                   <Input
                    label={''} 
                    name={'subTema'} 
                    onChange={handleOnchange} 
                    value={dtForm.temaPost}
                    disabled={false}
                   />
                </div>
                <Button
                    text={'AGREGAR'}
                    onClick={handleAddSubTema}
                />
            </div>
        </ModalSmail>}
        <Form>
            <div className="row">
                <div className="col col-10">
                   <Input
                    label={'Tema de publicación :'} 
                    onChange={handleOnchange} 
                    value={dtForm.temaPost}
                    disabled={false}
                   />
                </div>
                <div className="col col-2">
                    <Select
                    label={'Tipo :'} 
                    onChange={handleOnchange} 
                    value={dtForm.temaPost}
                    disabled={false}
                    dtSelect={dtTipoDePost}
                    />
                </div>
           </div>
           <div className="row">
                <div className="col col-12">
                   <Input
                    label={'Url generada :'} 
                    onChange={handleOnchange} 
                    value={dtForm.url}
                    disabled={false}
                   />
                </div>
           </div>
           <div className="row">
           
                <div className="col col-12">
                <Select
                    label={'Menu :'} 
                    onChange={handleOnchange} 
                    value={dtForm.temaPost}
                    disabled={false}
                    dtSelect={dtMenuSelect}
                    />
               </div>
            </div>
            <div className="row">
           
                <div className="col col-11">
                <Input
                    label={'Tematica de curso :'} 
                    onChange={handleOnchange} 
                    name={'tematica'} 
                    value={dtForm.temaPost}
                    disabled={false}
                />
               </div>
               <div className="col col-1">
                    <Button
                        text={'AGREGAR'}
                        onClick={handleAddTema}
                    />
               </div>
               
            </div>
            <div className='row'>
                    <div className='col col-12'>
                        <div className={estilosForm.lista}>
                            {dtTemaFinal.map((dato) => {
                                if(idCab!=dato.id && dato.idTema==null){
                                    idCab=dato.id
                                    return (
                                        <div key={dato.id}>
                                            <p className={estilosForm.lista__p}>
                                                {dato.nombre} 
                                                <img 
                                                    className={estilosForm.operaciones__icon} 
                                                    src={'../../assets/img/plus-solid.svg'}
                                                    onClick={()=>openModalSamal(dato.id)}
                                                />
                                                <img 
                                                    className={estilosForm.operaciones__icon} 
                                                    src={'../../assets/img/trash-solid.svg'}
                                                    onClick={()=>handleDeleteTema(dato.id)}
                                                    
                                                />
                                            </p>
                                            {dtTemaFinal.map((dato) => {
                                            if(idCab==dato.idTema){
                                                return (
                                                    <div key={dato.id} className={estilosForm.lista__item}>
                                                        {dato.nombre}
                                                        <a
                                                            className={estilosForm.lista__item_eliminar}>
                                                            <img 
                                                                className={estilosForm.operaciones__icon} 
                                                                src={'../../assets/img/eraser-solid.svg'}
                                                                onClick={()=>handleDeleteSubTema(dato.id)}
                                                            />
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>)
                                }
                            })}
                        </div>
                    </div>
                </div>
                
                <div className='row'>

                    <div className='col col-12'>
                        <TextTarea
                            label={'Descripcion  :'}
                        ></TextTarea>
                    </div>

                </div>

                <div className='row'>
                    <div className='col col-12'>
                        <TextEditor
                        label={'Contenido  :'}
                        ></TextEditor>
                    </div>
                </div>
            
            </Form>
        </Container>
    )
}
export default Registro;