import estilos from './Form.module.css'
const TextTarea = (props) => {
    return(
        <>
            <label className={estilos.label}>
				{props.label} 
			</label>
            <textarea
                className={estilos.borde}
                rows="4" // Número de filas
                cols="50" // Número de columnas
                onChange={props.onChange} // Manejador de cambios
            ></textarea>
        </>    
       
    )
}

export default TextTarea;