import {Routes,Route, Navigate } from 'react-router-dom'
import Registro from './Registro'
import Lista from './Lista'
import TheHeader from '../../components/header/TheHeader';
const Routers = () =>{
    //<Navigate to ='/home' />
	return (
        <>
            <TheHeader/>
            <Routes>
                <Route path='/admin/blog/lista' element={<Lista />} />
                <Route path='/admin/blog/registro' element={<Registro />} />
            </Routes>
        </>
    );
}

export default Routers;