import { useState } from "react";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import  estilos  from './Input.module.css';

const TextEditor = (props) => {
    const [value, setValue] = useState('');
    
    let toolbar = null;
    
    if(!props.usoImagen){
       
        toolbar =   [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ]
    }

    if(props.usoImagen){//solo para imagen
        toolbar =   [
            ['image'],
        ]
    }

    const modules = {
        toolbar: toolbar,
    }

   return ( 
    <a>
        <label className={estilos.label}>{props.label}</label>
        <ReactQuill 
            theme="snow"
            name={''}
            onChange={props.onChange}
            modules={modules}
        />
    </a>
   )
}

export default TextEditor;