import style from './LoginUser.module.css'
import Input from '../components/UI/Input'
import Alert from '../components/UI/Alert'
import useSetForm from '../hooks/useSetForm'
import useOnClickSendFormLoginUser from './useOnClickSendFormLoginUser'

const LoginUser = () => {
    const {handleOnClickSendFormLoginUser, errorsForm} = useOnClickSendFormLoginUser()
    const {handleOnChangeSetForm, form} = useSetForm();

    return (
    	<div className={style.container}>
            <form 
                className={style.form} 
                onSubmit={(e) => handleOnClickSendFormLoginUser(e,form)}
            > 
                <h1 className={"h1"}>Entra con los datos de acceso</h1>
                <input 
                    placeholder={'Usuario'} 
                    className="input" 
                    name="email" 
                    type="text" 
                    onChange={handleOnChangeSetForm}
                /> 
                <input 
                    placeholder={'Contraseña'} 
                    className="input" 
                    name="password" 
                    type="password"
                    onChange={handleOnChangeSetForm}
                /> 
                {errorsForm[0] && <Alert text={errorsForm[0]} />}
                <button type={'submit'} className="button">Entrar</button>
            </form>
        </div>
    )
}

export default LoginUser