import { useState , useEffect}  from 'react'
import BlogService from '../services/BlogService'
import useFormLoginUserValidate from './useFormLoginUserValidate'
import {useNavigate} from 'react-router-dom'
import t from '../services/TokenService'

const useOnClickSendFormLoginUser = () => {
    const {errorsForm,handleValidateForm,setErrorsForm} = useFormLoginUserValidate()
    const navigate = useNavigate();
   
    const handleOnClickSendFormLoginUser = async  (e,form) => {
    e.preventDefault()
    const isValid = handleValidateForm(form);

    if (isValid) {
      const data = BlogService.postData('login-user',form).then((data)=> {
         if(data.status===true){

            t.setToken(data.user)
            navigate('/user-cursos');

         }else{

            const errors = [];
            errors.push(data.message);
            setErrorsForm(errors)

         }
      })
    }
  }

    return {errorsForm,handleOnClickSendFormLoginUser}
}
export default useOnClickSendFormLoginUser;