import {useState} from 'react'

const useSetForm = () => {
  const [ form, setFrom ] = useState({})

  const handleOnChangeSetForm = (e) => {
    const { name, value } = e.target;
    setFrom({
      ...form,
      [name]:value
    })

  }

	return {handleOnChangeSetForm,form}
}

export default useSetForm;