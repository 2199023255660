import {API_URL} from './apiUrl'
import { useState } from 'react'
class NavService {
    eliminarItemMenu = (id) => {
        const parametro = {
            'id':id
        }
        const options = {
			method: 'DELETE',
			headers: {
			  'Content-Type': 'application/json',
			},
            body: JSON.stringify(parametro),
		}
        try {
            fetch(`http://localhost/apiblog/public/api/admin/eliminar-item-menu`,options) 
            .then(response => response.json())
            .then(datos => {
                return 'dddd';
            }) 
            
        } catch (error) {
            throw error;
        }
    }
    getBlog = async () => {
        try {
            const response  = await fetch(`${API_URL}blog`) 
            const data = response.json()
            return data;
        } catch (error) {
            throw error;
        }
    }
    getBlogDetail = async (url) => {
        try {
            const response  = await fetch(`${API_URL}blog/detail/`+url) 
            const data = response.json()
            return data;
        } catch (error) {
            throw error;
        }
    }
}
export default new NavService();