import estilos from './Form.module.css'
const Input = (props) => {

    let atributosCombinados = [];

    if(props.onChange){

        let atributos = {
            onChange: props.onChange
        };

        atributosCombinados.push(atributos);
    }

    
    if(props.value){

        let atributos = {
            value: props.value
        };

        atributosCombinados.push(atributos);
    }

    if(props.placeholder){

        let atributos = {
            placeholder: props.placeholder
        };

        atributosCombinados.push(atributos);
    }

    if(props.disabled){

        let atributos = {
            disabled: props.disabled
        };

        atributosCombinados.push(atributos);
    }
    
    let atributosFinales = atributosCombinados.reduce((acc, curr) => {
        return { ...acc, ...curr };
    }, {});

    return(
        <>
            <label className={estilos.label}>
				{props.label} 
			</label>
           <select className={estilos.input}>
                {
                    props.dtSelect && props.dtSelect.map((dt) => (
                        <option key={dt.value} value={dt.value}>
                             {dt.text} 
                        </option>
                    ))
                }
            </select>
        </>    
       
    )
}

export default Input;