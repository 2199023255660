import { AiOutlineReload } from "react-icons/ai";
import style from './IconLoader.module.css'

const IconLoader = ({onClick}) => {
   return(
        <div className={style.container}>
            <AiOutlineReload onClick={onClick} className={style.iconLoader} />
        </div>
   )
}

export default IconLoader;