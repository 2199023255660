import TheNav from '../../components/nav/TheNav'
import estilos from './TheHeader.module.css'
import Logo from './Logo'
import IconUser from '../../components/user/IconUser'
import useMediaQuery from '../../hooks/useMediaQuery'

const TheHeader = () => {
   const isMobile = useMediaQuery('(max-width: 767px)');

   return(
        <header className={estilos.header}>
           <Logo/>
           <TheNav/>
           {/*{!isMobile && <IconUser/>}*/}
        </header>
   )
}

export default TheHeader;