import styles from './Alert.module.css'

const Alert = ({text}) => {
    return(
        <div className={styles.container}>
            <p className={styles.alert}>{text}</p>
        </div>
    )
}

export default Alert;