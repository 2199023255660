import estilos from '../components/body/TheMain.module.css'
import BlogService from '../services/BlogService'
import {RUTA_CONTENT} from '../data/rutas'
import { BiSolidLockAlt} from "react-icons/bi";
import { BiSolidLockOpen } from "react-icons/bi";
import {useState,useEffect} from 'react'
import Section from '../components/body/Section'
import Loader from '../components/UI/Loader'
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineCoffee } from "react-icons/ai";
import { Link } from 'react-router-dom';

const CursosVideos = () => {
    const [dtCursosVideos, setDtCursosVideos] = useState(null)
    const [loader, setLoader] = useState(false)
  
      useEffect(() => {
         setLoader(true);
         const data = BlogService.getBlog('cursos').then((data)=> {
            setDtCursosVideos(data.dtCursosVideos)
            setLoader(false);
         })
      },[])

      let idElemenCab = 0
      let idCab = 0
      let count;

      const handlerShowCompuesto = (id,padlock) => {
         const content = document.getElementById(id+'compuesto')
         
         if(padlock==0){
            if (content.style.maxHeight){
               content.style.maxHeight = null;
            } else {
               content.style.maxHeight = content.scrollHeight + "px";
            }
         }
      }

      const Lista = () => {
      
      let idElemenCab = 0
      let idCab = 0
      let count;

      const handlerShowCompuesto = (id,padlock) => {
         const content = document.getElementById(id+'compuesto')
         
         if(padlock==0){
            if (content.style.maxHeight){
               content.style.maxHeight = null;
            } else {
               content.style.maxHeight = content.scrollHeight + "px";
            }
         }
      }
      
      return(
         <div className={`${estilos.posts}`} >
            {
               dtCursosVideos && dtCursosVideos.map((dato) => {
                  if(idCab!=dato.id){
                     idCab = dato.id;

                     return (
                        <div 
                        key= {dato.id} 
                        className={
                           `${estilos.posts__post} ${estilos.posts__cursos}
                            ${dato.compuesto == 1 ? estilos.compuesto : ''}`
                        }>
                  
                           <div className={estilos.posts__post_imagen}>   
                              <img 
                              className={estilos.posts__img} 
                              src={`${RUTA_CONTENT+dato.rutaImg}`}
                              />
                              <h1 
                                 className={estilos.cursos__h1}>
                                 {dato.nombre}
                              </h1>
                               <div className={estilos.posts__footer}>
                                   <Link to={'/user-ruta/'+dato.id}><button className={estilos.posts__leer_mas}>VER</button></Link> 
                                </div>
                              <p className={estilos.posts__p}>
                                 {dato.descripcion}
                              </p>  
                             
                           </div>
                           {
                              dato.compuesto == 1 && (
                           
                                 <div className={estilos.posts__descripcion}>
                                    <h1 className={estilos.posts__h1_add}>De que se compone</h1>
                                    <div className={estilos.posts__componentes}> 
                                       { 
                                          dtCursosVideos.map((datElemen) => {
                                             if(idCab==datElemen.idPosts && datElemen.idElemenCab!=idElemenCab  ){
                                                idElemenCab = datElemen.idElemenCab
                                                count = 0;
                                                let  idGrupo = 0;

                                                return (
                                                   <>
                                                      <h1 
                                                         onClick={() => handlerShowCompuesto(datElemen.idElemenCab,datElemen.padlock)} 
                                                         className={`${dato.compuesto == 1 ? estilos.collapsible : ''}`}
                                                      >
                                                         {datElemen.nomElemenCab}   
                                                         {
                                                            datElemen.progreso == 1 ? 
                                                               <AiOutlineCheck  className={`${estilos.padlock} ${estilos.padlock_open}`}/> : 
                                                               <AiOutlineCoffee className={`${estilos.padlock} ${estilos.padlock_close}`}/>
                                                         }

                                                      </h1>

                                                      <div id={`${datElemen.idElemenCab}compuesto`} className={` ${dato.compuesto == 1 ? estilos.content : ''}`}>
                                                         {
                                                            dtCursosVideos.map((datElemenItem) => {
                                                            
           
                                                            
                                                               if(idElemenCab==datElemenItem.idPostsCabs){

                                                                     
                                                                  let nomGrupo = ''

                                                                  if(idGrupo!=datElemenItem.idGrupo){
                                                                     nomGrupo = <p className={estilos.posts__grupo_p}>{datElemenItem.nomGrupo}</p>;
                                                                     idGrupo = datElemenItem.idGrupo
                                                                  }
                                                
                                                                  count++
                                                                  return(
                                                                       <>
                                                                  
                                                                     {  nomGrupo } 

                                                                     <p className={estilos.posts__componentes_item}>
                                                                        <span className={estilos.posts__componentes_item_span}>{count}</span> 
                                                                        {datElemenItem.nomElemenCabItem}
                                                                     </p>
                                                                      </>
                                                                  )
                                                               }
                                                            })//fin del map ultimo
                                                         }
                                                      </div>
                                                   </>
                                                )
                                             }
                                          })//fin del map segundo
                                       }
                                    </div>
                                   
                                 </div>
                              )
                           }
                        </div>
                     )
                  }
               })//fin del map principal
            }
         </div>
      )
   }
      
    return(
        <div className={estilos.main}>
            {
                loader ?  <Loader/> :
                <>
                    <Section>
                        <h1 className={estilos.h1}>TUS CURSOS Y VIDEOS</h1>
                        <Lista/>
                    </Section>: ''
                    
                </>
            }
        </div>
    )
}

export default CursosVideos