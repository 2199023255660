
import estilos from './Form.module.css'
const Form = ({children}) => {
   return(
        <form className={estilos.form}>
           {children}
        </form>
   )
}

export default Form;