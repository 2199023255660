class TokenService {
    setToken = (dtUsuario) => {
        localStorage.removeItem('bg_user');
        localStorage.setItem('bg_user',JSON.stringify(dtUsuario));
    }

    getToken = () => {
        return   JSON.parse(localStorage.getItem('bg_user'));
    }

    deleteToken = () => {
        localStorage.removeItem('bg_user');
    }

}

export default new TokenService();