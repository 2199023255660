import estilos from './Ruta.module.css'
import BlogService from '../services/BlogService'
import {RUTA_CONTENT} from '../data/rutas'
import { BiSolidLockAlt} from "react-icons/bi";
import { BiSolidLockOpen } from "react-icons/bi";
import {useState,useEffect} from 'react'
import Section from '../components/body/Section'
import Loader from '../components/UI/Loader'
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineCoffee } from "react-icons/ai";
import { Link , useParams} from 'react-router-dom';
import { AiOutlineReload } from "react-icons/ai";
import IconLoader  from "../components/UI/IconLoader";
import LoaderResponse  from "../components/UI/LoaderResponse";
import { AiOutlineClockCircle } from "react-icons/ai";
import ModalSmaill from "../components/UI/ModalSmall";
import useOnClickShowModalTarea from './useOnClickShowModalTarea'
import { BiArrowBack } from "react-icons/bi";
import t from '../services/TokenService'

const Ruta = () => {
    const [dtCursosVideos, setDtCursosVideos] = useState(null)
    const [dtTareas, setDtTareas] = useState(null)
    const [loader, setLoader] = useState(false)
    const [screen, setScreen] = useState(false)
    const { id } = useParams(); // Obtener el id de la URL
    const {show,handleOnClickShowModalTarea,tareaContent, tareaName} = useOnClickShowModalTarea()
    const blUser = t.getToken()
  
      useEffect(() => {
         load()
      },[])

      const load = () => {
         setLoader(true);
         const data = BlogService.getData('blog/curso/',id).then((data)=> {
            setDtCursosVideos(data.dtCursosVideos)
            setDtTareas(data.dtTareas)
            setLoader(false);
         })
      }

      let idElemenCab = 0
      let idCab = 0
      let count;

      const handlerShowCompuesto = (id,padlock) => {
         const content = document.getElementById(id+'compuesto')
         
         if(padlock==0){
            if (content.style.maxHeight){
               content.style.maxHeight = null;
            } else {
               content.style.maxHeight = content.scrollHeight + "px";
            }
         }
      }

      const Lista = () => {
      
      let idElemenCab = 0
      let idCab = 0
      let count;

      const handleOnclickCheckTarea = (id) => {
         setDtTareas((prevTareas) =>
            prevTareas.map((tarea) =>
               tarea.id === id
                  ? { ...tarea, checked: true,disabled: true}
                  : tarea
               )
         );

         const form = {
            id:id,
            idUsu:12
         }

         const data = BlogService.postData('blog/tarea-realizada',form).then((data)=> {})
      }

      const handleOnclickCheckItem = (id) => {

         setDtCursosVideos((prevCurso) =>
            prevCurso.map((curso) =>
               curso.idElemenCabItem === id
                  ? { ...curso, checked: true,disabled: true}
                  : curso
               )
         );

         const form = {
            id:id,
            idUsu:12
         }

         const data = BlogService.postData('blog/item-realizado',form).then((data)=> {})
      }

     

      return(
         <div className={`${estilos.posts}`} >
            <IconLoader onClick={handleOnClickUpdateScreen}/>  
            {
               dtCursosVideos && dtCursosVideos.map((dato) => {
                  if(idCab!=dato.id){
                     idCab = dato.id;

                     return (
                        <div 
                        key= {dato.id} 
                        className={
                           `${estilos.posts__post} ${estilos.posts__cursos}
                            ${dato.compuesto == 1 ? estilos.compuesto : ''}`
                        }>
                  
                           <div className={estilos.posts__post_imagen}>   
                           
                              <div className={estilos.posts__footer}>
                                 <Link to={'/user-cursos/'}>
                                     <BiArrowBack className={estilos.atras} />
                                 </Link> 
                              </div> 
                             
                           </div>
                           {
                              dato.compuesto == 1 && (
                           
                                 <div className={estilos.posts__descripcion}>
                                    <h1 className={estilos.posts__h1_add}>Progreso</h1>
                                    <div className={estilos.posts__componentes}> 
                                       { 
                                          dtCursosVideos.map((datElemen) => {
                                             if(idCab==datElemen.idPosts && datElemen.idElemenCab!=idElemenCab  ){
                                                idElemenCab = datElemen.idElemenCab
                                                count = 0;
                                                let  idGrupo = 0;


                                                return (
                                                   <>
                                                      <h1 
                                                         onClick={() => handlerShowCompuesto(datElemen.idElemenCab,datElemen.padlock)} 
                                                         className={`${dato.compuesto == 1 ? estilos.collapsible : ''}`}
                                                      >
                                                         {datElemen.nomElemenCab} 
                                                      
                                                         {
                                                            datElemen.numCabItem == datElemen.numCabItemUser ? 
                                                               <AiOutlineCheck  className={`${estilos.padlock} ${estilos.padlock_open}`}/> : (
                                                                 datElemen.numCabItemUser == 0 ?  
                                                                     <AiOutlineClockCircle className={`${estilos.padlock}`} /> : <AiOutlineCoffee className={`${estilos.padlock} ${estilos.padlock_close}`}/> 
                                                               )
                                                               
                                                         }

                                                          {/*<AiOutlineClockCircle />*/}
                                                         
                                                       
                                                      </h1>
                                                

                                                      <div id={`${datElemen.idElemenCab}compuesto`} className={`${datElemen.progreso == 1 ? estilos.content_item : estilos.content}`}>
                                                         {
                                                            dtCursosVideos.map((datElemenItem) => {
                                                            
           
                                                              const tareas = dtTareas.map((datTareas) => {
                                                                  if (datTareas.idCabItem==datElemenItem.idElemenCabItem) {
                                                                     return ( 
                                                                        <p key={datTareas.id}  
                                                                           className={estilos.curso_tarea} 
                                                                           onClick={() => {
                                                                              if (blUser.rol==1) { 
                                                                                 handleOnClickShowModalTarea(datTareas.id)  
                                                                              }  
                                                                           }}>
                                                                           {datTareas.nombre}  
                                                                           <input
                                                                              type="checkbox" 
                                                                              
                                                                              onClick={() => { handleOnclickCheckTarea(datTareas.id); }}
                                                                              
                                                                              disabled={datTareas.disabled || false || datTareas.progresoTarea}
                                                                              checked={datTareas.progresoTarea || false  || datTareas.checked}
                                                                           />
                                                                        </p>
       
                                                                     );
                                                                  } else {
                                                                     return null; // No renderizar nada si no se cumple la condición
                                                                  }
                                                               });

                                                               if(idElemenCab==datElemenItem.idPostsCabs){

                                                                     
                                                                  let nomGrupo = ''

                                                                  if(idGrupo!=datElemenItem.idGrupo){
                                                                     nomGrupo = <p className={estilos.posts__grupo_p}>{datElemenItem.nomGrupo}</p>;
                                                                     idGrupo = datElemenItem.idGrupo
                                                                  }
                                                
                                                                  count++
                                                                  return(
                                                                       <div key={datElemenItem.idElemenCabItem}>
                                                                  
                                                                     {  nomGrupo } 

                                                                     <p className={estilos.posts__componentes_item}>
                                                                        <span className={estilos.posts__componentes_item_span}>{count}</span> 
                                                                        {datElemenItem.nomElemenCabItem}  
                                                                          <input
                                                            type="checkbox" 
                                                            onClick={() => handleOnclickCheckItem(datElemenItem.idElemenCabItem)} 
                                                            disabled={datElemenItem.disabled || false || datElemenItem.progresoItem}
                                                            checked={datElemenItem.progresoItem || false  || datElemenItem.checked}
                                                         />
                                                                     </p>
                                                                     <div  className={estilos.contenedor_curso_tarea}> { tareas}</div>
                                                                      </div>
                                                                  )
                                                               }
                                                            })//fin del map ultimo
                                                          }

                                                         
                                                      </div>
                                                   </>
                                                )
                                             }
                                          })//fin del map segundo
                                       }
                                    </div>
                                   
                                 </div>
                              )
                           }
                        </div>
                     )
                  }
               })//fin del map principal
            }
         </div>
      )
   }

   const handleOnClickUpdateScreen = ()=> {
      load()
   }

    return(
        <div className={estilos.main}>
            {
               loader ?  <Loader/> :
               <>
                  <Section>
                     <h1 className={estilos.h1}>RUTA DEL CURSO DE PHP </h1>
                     <Lista/>
                  </Section>: ''
                    
               </>
            }
            {show && 
               <ModalSmaill 
                  onClick={handleOnClickShowModalTarea} 
                  titulo={tareaName}
               >
               <pre className={estilos.pre}><code className={estilos.code}>
               {`${tareaContent}

               `}
              </code></pre>
            </ModalSmaill>}
        </div>
    )
}

export default Ruta