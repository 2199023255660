import estilos from './TheNav.module.css'
import {useEffect,useState} from 'react'
import NavService from '../../services/NavService'
import { Link} from 'react-router-dom';
//_nav_seleccionado" 
/*[
        {'id':1, 'nombre':'Blog','url':'admin/blog/lista'},
        {'id':2,'nombre':'Categorias','url':'admin/categorias/lista'}
    ] */
const TheNav = () => {
    const [dtMenu, setDtMenu] = useState()

    const currentPath = window.location.pathname;
    const parts = currentPath.split('/'); 
    const admin = parts[1]; 
    
    useEffect(() => {
        if(admin!='admin')
        {
            const data = NavService.getBlog().then((data)=> {
                setDtMenu(data.dtMenu);
            })
        }
    },[])
    return(
        <nav className={estilos.nav}>
            <ul  className={estilos.nav__ul}>
                {
                    dtMenu && dtMenu.map((dato) => (
                        <li key={dato.id} className={estilos.nav__li}>
                            <Link className={estilos.nav__a} to={dato.url}>{dato.nombre}</Link>
                        </li>
                    ))
                }
            </ul>
        </nav>
   )
}

export default TheNav;