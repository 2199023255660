import estilos from './Loader.module.css'

const Loader = (props) => {
    return(
        <div className={estilos.loader_content}>
            <span className={estilos.loader}></span>
        </div>
    )
}

export default Loader;