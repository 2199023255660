import { useState , useEffect}  from 'react'

const useFormLoginUserValidate = () => {
    const [errorsForm,setErrorsForm] = useState([])
   
    const handleValidateForm = (form) => {
      
      const errors = [];

      if (!form.email) {
        errors.push('Digite su usuario');
      }
      if (!form.password) {
        errors.push('Digite la contraseña');
        
      }
      if (form.password) {
        if (form.password.length < 2 ) {
          errors.push('La contraseña debe tener mas de 2 caracteres');
        }
      }

      setErrorsForm(errors);

      return errors.length === 0;
    } 

    return {errorsForm,handleValidateForm,setErrorsForm}
}
export default useFormLoginUserValidate;