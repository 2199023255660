import estilos from './Form.module.css'

const Button = (props) => {
    return(
        <div className={estilos.container_item}>
            <a   className={`${estilos.button} ${props.mini_button}`}
                href={props.src} 
                onClick={props.onClick}
             >
                {props.text}
            </a>
        </div>
    )
}

export default Button;