import estilos from './TheMain.module.css'
import Section from './Section'
import {useEffect,useState} from 'react'
import BlogService from '../../services/BlogService'
import Menssage from './Message'
import Loader from '../../components/UI/Loader'
import {RUTA_CONTENT} from '../../data/rutas'
import { BiSolidLockAlt} from "react-icons/bi";
import { BiSolidLockOpen } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';

const  Content = ({location,urls}) => {

   const [urlMenu, setUrlMenu] = useState(null)
   const [dtPost, setDtPost] = useState([])
   const [dtCursosVideos, setDtCursosVideos] = useState(null)
   const [dtProyectos, setDtProyectos] = useState(null)
   const [loader, setLoader] = useState(false)

   let idCab = 0
  
   useEffect(() => {
        setLoader(true);
        const data = BlogService.getBlog(urls.menu).then((data)=> {
            setDtPost(data.dtPost)
            setDtCursosVideos(data.dtCursosVideos)
            setDtProyectos(data.dtProyectos)
            setLoader(false);
        })
        setUrlMenu(urls.menu)
   },[location])

   const Post = () => {
      return(
         <div className={estilos.posts}>
            { 
               dtPost && dtPost.map((dato) => (
                  <div key= {dato.id} className={estilos.posts__post}>
                     <img className={estilos.posts__img} src={`${RUTA_CONTENT+dato.rutaImg}`}/>
                     <h1 className={estilos.posts__h1}>{dato.nombre}</h1>
                     <p className={estilos.posts__p}>
                        {dato.descripcion}
                     </p>  
                     <div className={estilos.posts__footer}>
                        <a href={`${dato.nomMenu}/${dato.url}`}  className={estilos.posts__leer_mas}> Leer mas..</a>
                     </div>
                  </div>
               ))
            }
         </div>
      )
   }

   const VideoCuros = () => {
      
      let idElemenCab = 0
      let idCab = 0
      let count;

      const handlerShowCompuesto = (id,padlock) => {
         const content = document.getElementById(id+'compuesto')
         
         if(padlock==0){
            if (content.style.maxHeight){
               content.style.maxHeight = null;
            } else {
               content.style.maxHeight = content.scrollHeight + "px";
            }
         }
      }
      
      const navigate = useNavigate();
      const handleAccederCurso = () => {
         navigate('/user-cursos');
      }
      
      return(
         <div className={`${estilos.posts}`} >
            {
               dtCursosVideos && dtCursosVideos.map((dato) => {
                  if(idCab!=dato.id){
                     idCab = dato.id;

                     return (
                        <div 
                        key= {dato.id} 
                        className={
                           `${estilos.posts__post} ${estilos.posts__cursos}
                            ${dato.compuesto == 1 ? estilos.compuesto : ''}`
                        }>
                  
                           <div className={estilos.posts__post_imagen}>   
                              <img 
                              className={estilos.posts__img} 
                              src={`${RUTA_CONTENT+dato.rutaImg}`}
                              />
                              <h1 
                                 className={estilos.cursos__h1}>
                                 {dato.nombre}
                              </h1>
                              <p className={estilos.posts__p}>
                                 {dato.descripcion}
                              </p>  
                             
                           </div>
                           {
                              dato.compuesto == 1 && (
                           
                                 <div className={estilos.posts__descripcion}>
                                    <h1 className={estilos.posts__h1_add}>De que se compone</h1>
                                    <div className={estilos.posts__componentes}> 
                                       { 
                                          dtCursosVideos.map((datElemen) => {
                                             if(idCab==datElemen.idPosts && datElemen.idElemenCab!=idElemenCab  ){
                                                idElemenCab = datElemen.idElemenCab
                                                count = 0;
                                                let  idGrupo = 0;

                                                return (
                                                   <>
                                                      <h1 
                                                         onClick={() => handlerShowCompuesto(datElemen.idElemenCab,datElemen.padlock)} 
                                                         className={`${dato.compuesto == 1 ? estilos.collapsible : ''}`}
                                                      >
                                                         {datElemen.nomElemenCab}   
                                                         {
                                                            datElemen.padlock == 0 ? 
                                                               <BiSolidLockOpen className={`${estilos.padlock} ${estilos.padlock_open}`}/> : 
                                                               <BiSolidLockAlt className={`${estilos.padlock} ${estilos.padlock_close}`}/>
                                                         }

                                                      </h1>

                                                      <div id={`${datElemen.idElemenCab}compuesto`} className={` ${dato.compuesto == 1 ? estilos.content : ''}`}>
                                                         {
                                                            dtCursosVideos.map((datElemenItem) => {
                                                            
           
                                                            
                                                               if(idElemenCab==datElemenItem.idPostsCabs){

                                                                     
                                                                  let nomGrupo = ''

                                                                  if(idGrupo!=datElemenItem.idGrupo){
                                                                     nomGrupo = <p className={estilos.posts__grupo_p}>{datElemenItem.nomGrupo}</p>;
                                                                     idGrupo = datElemenItem.idGrupo
                                                                  }
                                                
                                                                  count++
                                                                  return(
                                                                       <>
                                                                  
                                                                     {  nomGrupo } 

                                                                     <p className={estilos.posts__componentes_item}>
                                                                        <span className={estilos.posts__componentes_item_span}>{count}</span> 
                                                                        {datElemenItem.nomElemenCabItem}
                                                                     </p>
                                                                      </>
                                                                  )
                                                               }
                                                            })//fin del map ultimo
                                                         }
                                                      </div>
                                                   </>
                                                )
                                             }
                                          })//fin del map segundo
                                       }
                                    </div>
                                    <div className={estilos.posts__footer}>
                                        <button onClick={handleAccederCurso} className={estilos.posts__leer_mas}>ACCEDER AL CURSO</button>
                                    </div>
                                 </div>
                              )
                           }
                        </div>
                     )
                  }
               })//fin del map principal
            }
         </div>
      )
   }

   const Proyectos = () => {

      return(
         <div className={estilos.proyectos}>
            {
               dtProyectos && dtProyectos.map((dato) => {
                 
                  if(idCab!=dato.id){
                     idCab = dato.id
                     return(
                        <div className={estilos.proyectos__proyecto}>
                           <div className={estilos.proyectos__left}>
                              <h1 className={estilos.proyectos__h1}>{dato.nombre}</h1>
                              <p className={estilos.proyectos__p}>
                                 {dato.descripcion}
                              </p>
                              <div className={estilos.posts__componentes_items}>
                              {
                                 dtProyectos.map((datElemen) => {
                                    if(idCab==datElemen.id && datElemen.idElemenCab!=null){
                                       return(
                                          <p className={estilos.posts__componentes_item}>
                                             <span className={estilos.posts__componentes_item_span}>
                                                <img className={estilos.proyectos__img_bottom} src={`${RUTA_CONTENT+'check-solid.svg'}`}/>
                                             </span> 
                                            {datElemen.nomElemenCab}
                                          </p>
                                       )
                                    }
                                 })//fin del map ultimo
                              }
                              </div>
                              <div className={estilos.proyectos__bottom}>

                                 {dato.conCodigo==1 ? 
                                    <a href={dato.urlCodigo} 
                                       className={estilos.proyectos__a} 
                                       target='_blank'>
                                       <img  
                                       className={estilos.proyectos__img_bottom} 
                                       src={`${RUTA_CONTENT+'github.svg'}`} 
                                       />
                                          Codigo
                                    </a>:''}
                                 
                                 {dato.conEnLinea==1 ? 
                                    <a className={estilos.proyectos__a} 
                                         href={dato.urlEnLinea} 
                                         target='_blank'>
                                         <img  
                                          className={estilos.proyectos__img_bottom} 
                                          src={`${RUTA_CONTENT+'globe-solid.svg'}`}
                                          /> Ver en linea 
                                    </a>:''}

                                    
                                 {dato.conDownload==1 ? 
                                    <a className={estilos.proyectos__a} 
                                       href={`${RUTA_CONTENT}${dato.urlDownload}`} 
                                       target='_blank' 
                                       download>
                                         <img  
                                          className={estilos.proyectos__img_bottom} 
                                          src={`${RUTA_CONTENT+'download-solid.svg'}`}
                                          download={dato.nomDownloa}
                                          /> Documento asociado
                                    </a>:''}

                                 {dato.conPago==1 ? 
                                    <a className={estilos.proyectos__a} 
                                       href={"https://api.whatsapp.com/send/?phone=3003991313"} 
                                       target='_blank' >
                                         <img  
                                          className={estilos.proyectos__img_bottom} 
                                          src={`${RUTA_CONTENT+'handshake.svg'}`}
                                          /> Obtener el código 
                                        
                                    </a>:''}
                              </div>
                           </div>
                           <div className={estilos.proyectos__right}>
                              <img className={estilos.proyectos__img}  src={`${RUTA_CONTENT+dato.rutaImg}`} />
                           </div>
                        </div>
                     )
                  }
               })
            }
         </div> 
      )
   }

    return(
        <div className={estilos.main}>
         {loader ?  <Loader/> :
             <>
                {urlMenu=='home'  ? 
                <div className={estilos.cabecera}>
                    <h1 className={estilos.cabecera_h1}>Bienvenido!</h1>
                    <p className={estilos.cabecera_p}>Aqui encontraras recusos de programación como video cursos, articulos y mas. </p>
                </div>: ''
                }

            { (urlMenu!='proyectos' && urlMenu!='cursos')  ? 
                <Section>
                    <h1 className={estilos.h1}>PUBLICACIONES</h1>
                    <Post/>
                </Section>: ''
            }

            { (urlMenu=='cursos' || urlMenu=='videos') ? 
                <Section>
                    {dtCursosVideos && <h1 className={estilos.h1}>CURSOS Y VIDEOS</h1> }
                    <VideoCuros/>
                </Section>: ''
            }

            
            {urlMenu=='home' || urlMenu=='proyectos'  ? 
                <Section>
                    <h1 className={`${estilos.h1} ${estilos.titulo_diferente}`}>PROYECTOS RELACIONADOS</h1>
                    <Proyectos/>
                </Section>: ''
            }
            {//dtPost.length == 0 && urlMenu!='proyectos'  ? (
               //<Menssage 
               //   preMessage={'Ups!'} 
               //   message={'Parece que no hay contenido sobre este tema; pero no te preocupes, lo puedes esperar!'} />
               //): ''
            }

             </>
         
         }
      </div>
    )
}
export default Content