
import estilos from './ModalSmall.module.css'
const ModalSmal = ({children,onClick, titulo}) => {
   return(
        <div className={estilos.modal}>
             <div className={estilos.modal__content}>
                <div className={estilos.modal__header}>
                    <h1 className={estilos.modal__h1}>{titulo}</h1> 
                    <span onClick={onClick} className={estilos.modal__close}>
                       &times;
                    </span>
                </div>
               <div className={estilos.modal__body}>
                    {children} 
               </div>
            </div>
        </div>
   )
}

export default ModalSmal;