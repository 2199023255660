import {API_URL} from './apiUrl'
import { useState } from 'react'
import fs from './TokenService'

class BlogService {
    getBlog = async (url) => {
       
        try {
            const response  = await fetch(`${API_URL}blog/`+url) 
            const data = response.json()
            return data;
        } catch (error) {
            throw error;
        }
    }

    getData = async (url,id) => {
        try {
            const response  = await fetch(`${API_URL}`+url+id) 
            const data = response.json()
            return data;
        } catch (error) {
            throw error;
        }
    }

    postData = async (url,form) => {

       
        if (url!='login-user') {
            const bgUser = fs.getToken();
            if (bgUser.token) {
                this.token  = bgUser.token;
            }
        }
    
        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.token}`,
            },

            body: JSON.stringify(form),
        }
       
        try {
            const response  = await fetch(`${API_URL}`+url,options) 
            const data = response.json()
            return data;
        } catch (error) {
            throw error;
        }
    }


    getBlogDetail = async (url) => {
        try {
            const response  = await fetch(`${API_URL}blog/detail/`+url) 
            const data = response.json()
            return data;
        } catch (error) {
            throw error;
        }
    }
    sendEmail = async (email) => {
        const form = {
            email:email
        }
        const options = {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			},
            body: JSON.stringify(form),
		}
        try {
            const response  = await fetch(`${API_URL}blog/save-email`,options) 
            const data = response.json()
            return data;
        } catch (error) {
            throw error;
        }
    }
}
export default new BlogService();