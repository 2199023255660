import estilos from './SendEmail.module.css'
import Tooltip from '../../components/UI/Tooltip'
import MiniLoader from '../../components/UI/MiniLoader'
import BlogService from '../../services/BlogService'
import { useState } from 'react'

const SendEmail = ({preMessage,message})=> {
    const [email,setEmail] = useState('')
    const [loader,setLoader] = useState(false)
    const [tooltip,setTooltip] = useState(false)

    const sendEmail = () =>{
        const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (regexEmail.test(email)) {

            setLoader(true)
            BlogService.sendEmail(email).then((data)=> {
                setLoader(false) 
                setTooltip(true) 
            })
            setTimeout(() => {
                setTooltip(false) 
            },3000)

        }
    }

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    return(
        <div className={estilos.message}>
            <p className={estilos.message__p}>
                Envia tu email para recibir notificaciones 
                acerca de los posts, cursos y mas</p>
            <div className={estilos.message__email}>
                <input 
                    placeholder="Aquí"
                    className={estilos.message__input}
                    onChange={handleSetEmail}
                /> 
                <button 
                    className={estilos.message__btn_send}
                    onClick={sendEmail}
                >ENVIAR</button>
                {
                  loader == true  && <MiniLoader />
                }
                {
                  tooltip == true &&  <Tooltip message={'Estas suscrito'} />
                }
            </div>
        </div>
    )
}
export default SendEmail;