import estilos from './Logo.module.css'
import IconUser from '../../components/user/IconUser'
import IconUserCss from '../../components/user/IconUser.module.css'
import useMediaQuery from '../../hooks/useMediaQuery'

const Logo = () => {
    const isMobile = useMediaQuery('(max-width: 767px)');

    return(
        <div className={estilos.logo}>
            <span className={estilos.logo__wilmer}></span>
            <span className={estilos.logo__code}>[WILMER_OVIEDO 
                <span className={estilos.logo__codigo}>_CODE</span>]
            </span>
            {/*{isMobile && <IconUser/>}*/}
        </div> 
   )
}

export default Logo;