import estilos from './TheFooter.module.css'
import SendEmail from '../body/SendEmail'
const TheFooter = () => {
  return(
   <footer className={estilos.footer}>
      <SendEmail/>
      <p className={estilos.footer_p}>Este sitio fue desarrollado por  Wilmer Oviedo   &#169; 2023</p>
   </footer> 
  )
}
export default TheFooter;