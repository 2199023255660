import estilos from './ContentDetail.module.css'
import {useEffect,useState} from 'react'
import React from 'react';
import ReactDOM from 'react-dom';

import  '../../App.css'

import SyntaxHighlighter from 'react-syntax-highlighter';
import { monoBlue} from 'react-syntax-highlighter/dist/esm/styles/hljs';

import NavService from '../../services/NavService'

import {useLocation} from 'react-router-dom';

import {RUTA_CONTENT} from '../../data/rutas'

const ContentDetail = ({children})=> {

  const location = useLocation();
  const currentPath = location.pathname;
  const parts = currentPath.split('/'); 
  const opcion = parts[2]; 

  const [dtDetail,setDtDetail] = useState({
    title:'',
    description:'', 
    img:'',
    content:''
  })

  useEffect(() => {
    const data = NavService.getBlogDetail(opcion).then((data)=> {
      setDtDetail({...dtDetail,
        title:data.dtPost.nombre,
        description:data.dtPost.descripcion,
        img:data.dtPost.rutaImg,
        content:data.dtPost.contenido
      })
    })
  },[])

  useEffect(() => {

    const languages = [
      'javascript',
      'html',
      'css',
      'php',
      // Agrega más lenguajes si es necesario
    ];
    
    // Obtén todos los elementos con la clase "codigo-a-validar"
    const codeElements = document.querySelectorAll('.codigo-a-validar');

    // Recorre cada elemento y aplica SyntaxHighlighter
    codeElements.forEach((element) => {
      
      const codeString = element.textContent.trim();


      
      const highlightedCode = (
      
        <div style={{flex: 1, width: '100%', flexDirection: 'column'}}>
        <SyntaxHighlighter 
          className={estilos.color}
          style={monoBlue} 
          wrapLines={true}
          showLineNumbers={true}
          language={languages}>
          {codeString}
        </SyntaxHighlighter>
        </div>
    
      );
      // Reemplaza el contenido del elemento con el código resaltado
      ReactDOM.render( highlightedCode, element);
    });
   
  }, [dtDetail.content]);
 

  return(
    <>
      <div className={estilos.cabecera}>
        <div className={estilos.cabecera__img_content}>
          <img className={estilos.cabecera__img} src={`${'../../assets/img/'+dtDetail.img}`} />
        </div>
        <div className={estilos.cabecera__titulo}>
          <h2 className={estilos.cabecera__h1}>{dtDetail.title}</h2>
          <p className={estilos.cabecera__p}>
            {dtDetail.description}
          </p>
        </div>
      </div>
      <div className={estilos.container}>
        <div className={estilos.container__sub}>
          <div className={estilos.p}>
            <div dangerouslySetInnerHTML={{ __html: dtDetail.content }} />
          </div>
        </div>
      </div>
    </>
  )
  
}

export default ContentDetail